import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />

      <section className="inner-banner">
      <div className="w3l-breadcrumb py-5" style={{paddingTop:"8rem"}}>
            <div className="container py-xl-5 py-md-4 mt-5">
              <h4 className="inner-text-title font-weight-bold mb-sm-2">Services</h4>
              <ul className="breadcrumbs-custom-path">
                <li><Link to="/Home">Home</Link></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true" />Services</li>
              </ul>
            </div>
          </div>
        </section>
        <div className="service-section py-5">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center mb-5">
            <div className="col-md-6 section-heading">
              <h5 className="small-title-2">Our Services</h5>
              <h3 className="title-style-2">What We Do</h3>
            </div>
            <div className="col-md-6 section-heading mt-md-0 mt-2">
              <p>
                Unlock the full potential of your supply chain with our
                top-notch freight services, delivering efficiency, reliability,
                and peace of mind.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 item">
              <div className="card">
                <div className="card-header p-0 position-relative border-0">
                  <Link to="/Air">
                    <img
                      className="d-block img-responsive"
                      src="assets/images/air.jpg"
                      alt="card-image "
                      style={{ height: "200px" }}
                    />
                  </Link>
                </div>
                <div className="card-body service-details">
                  <span className="label-style">Service</span>
                  <Link to="/Air" className="service-heading">
                    Air Freight
                  </Link>
                  <p>
                  With our extensive expertise in the logistics industry, we
                  take pride in offering comprehensive shipment arrangements on
                  a global scale, utilizing both air and sea modes. Our
                  specialized guidance ensures that you receive the most
                  cost-effective and reliable routing, facilitating swift and
                  economical delivery of valued orders to your clients. We
                  meticulously monitor carrier status to ensure timely and
                  secure delivery, putting your mind at ease throughout the
                  shipping process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item">
              <div className="card">
                <div className="card-header p-0 position-relative border-0">
                  <Link to="/Sea">
                    <img
                      className="d-block img-responsive"
                      src="assets/images/sea.jpg"
                      alt="card-image "
                      style={{ height: "200px" }}
                    />
                  </Link>
                </div>
                <div className="card-body service-details">
                  <span className="label-style">Service</span>
                  <Link to="/Sea" className="service-heading">
                    Sea Freight
                  </Link>
                  <p>
                  {" "}
                  Our global reach allows us to efficiently arrange Full
                  Container Load (FCL) and Less than Container Load (LCL)
                  shipments for our esteemed customers across the world. With a
                  strong emphasis on fostering positive relationships with
                  leading liners, we can secure competitive prices and top-notch
                  services for our clients. Rest assured, your goods will be
                  delivered swiftly, accurately, and securely, ensuring utmost
                  satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item">
              <div className="card">
                <div className="card-header p-0 position-relative border-0">
                  <Link to="/Surface">
                    <img
                      className="d-block img-responsive"
                      src="assets/images/surface.jpg"
                      alt="card-image "
                      style={{ height: "200px" }}
                    />
                  </Link>
                </div>
                <div className="card-body service-details">
                  <span className="label-style">Service</span>
                  <Link to="/Surface" className="service-heading">
                    Surface Freight
                  </Link>
                  <p>
                  Leveraging cutting-edge vehicle tracking technologies, we
                  excel in arranging both Full Truck Load (FTL) and Less Than
                  Truck Load (LTL) shipments across India. Our advanced tracking
                  systems ensure real-time monitoring and provide precise
                  updates on the movement of your cargo. Offering a
                  comprehensive point-to-point delivery service, we cater to the
                  needs of our customers, ensuring their goods reach their
                  destination conveniently, no matter where they are located.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <section className="w3l-team py-5">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center mb-5">
            <div className="col-md-6 section-heading">
              <h5 className="small-title-2">What We Provide </h5>
              <h3 className="title-style-2">Other Services</h3>
            </div>
            <div className="col-md-6 section-heading mt-md-0 mt-2">
             
            </div>
          </div>
          <div className="row text-center">
            <div className="col-lg-4 col-sm-6">
              <div className="team-block-single">
                <div className="team-grids">
                  <Link to="/Custom">
                    <img
                      src="assets/images/custom.webp"
                      style={{height:"200px"}}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="team-info">
                  <Link
                  to="/Custom"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
                  </div>
                </div>
                <div className="team-bottom-block p-4">
                  <h5 className="member mb-1">
                    <Link to="/Custom">Custom Clearance</Link>
                  </h5>
                  <small>Service</small>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="team-block-single">
                <div className="team-grids">
                  <Link to="/Courier">
                    <img
                      src="assets/images/courier.jpg"
                      style={{height:"200px"}}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="team-info">
                  <Link
                  to="/Courier"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
                  </div>
                </div>
                <div className="team-bottom-block p-4">
                  <h5 className="member mb-1">
                    <Link to="/Courier">Courier Freight</Link>
                  </h5>
                  <small>Service</small>
                </div>
              </div>
            </div>

             <div className="col-lg-4 col-sm-6">
              <div className="team-block-single">
                <div className="team-grids">
                  <Link to="/Warehousing">
                    <img
                      src="assets/images/warehousing.jpg"
                      style={{height:"200px"}}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="team-info">
                  <Link
                  to="/Warehousing"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
                  </div>
                </div>
                <div className="team-bottom-block p-4">
                  <h5 className="member mb-1">
                    <Link to="/Warehousing">Warehousing</Link>
                  </h5>
                  <small>Service</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
