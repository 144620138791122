import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="inner-banner">
        <div className="w3l-breadcrumb py-5" style={{ paddingTop: "8rem" }}>
          <div className="container py-xl-5 py-md-4 mt-5">
            <h4 className="inner-text-title font-weight-bold mb-sm-2">
            Warehousing
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-chevron-right mx-2" aria-hidden="true" />
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-6 py-5" id="about">
        <div className="text-6-mian py-md-5 py-4">
          <div className="container">
            <div className="row top-cont-grid align-items-center">
              <div className="col-lg-12 left-img pr-lg-4 order-lg-first order-last">
                <img
                  src="assets/images/warehousing.jpg"
                  alt=""
                  className="img-responsive img-fluid"
                />
              </div>
              <div className="col-lg-12 text-6-info mb-lg-0 mb-5 pl-xl-5 order-lg-last order-first">
                <h5 className="small-title-2">
                  {" "}
                  <br />
                </h5>
                <h3 className="title-style-2 mb-3">Warehousing</h3>
                <p>
                  Our company takes pride in offering a complete service package
                  that caters to the crucial aspects of warehousing,
                  strategically located nearby automotive, engineering,
                  pharmacy, and IT hubs. This advantageous positioning ensures
                  efficient access to major industries, facilitating smooth and
                  swift transportation of goods. We understand the significance
                  of well-organized warehouse management systems, and our team
                  of experts excels in providing the highest level of service
                  and security for your valuable cargo.
                  <br />
                  <br />
                  Our warehouses are designed to meet the diverse needs of
                  various industries, making them ideal storage solutions for a
                  wide range of products. With state-of-the-art facilities and
                  cutting-edge technology, we ensure seamless loading and
                  unloading processes to optimize the efficiency of operations.
                  Our warehouses are equipped with advanced physical scanning
                  systems, allowing for accurate tracking and monitoring of your
                  inventory. In addition, our experienced staff meticulously
                  stack goods, optimizing space utilization and minimizing the
                  risk of damage during handling.
                  <br />
                  <br />
                  As part of our comprehensive service, we offer transportation
                  services for both pickup and delivery. This means that we take
                  care of the entire logistics process, from the moment your
                  goods leave the supplier's door to their arrival at the
                  customer's location. By offering this end-to-end solution, we
                  streamline the supply chain and eliminate any potential
                  disruptions, providing a hassle-free experience for our
                  clients.
                  <br />
                  <br />
                  When you entrust your warehousing needs to us, you can rest
                  assured that your cargo is in safe hands. Our team of
                  professionals is dedicated to maintaining the highest
                  standards of security and efficiency, ensuring that your goods
                  are handled with the utmost care and delivered promptly. With
                  our complete service from one source, you can focus on your
                  core business operations, knowing that your warehousing needs
                  are being met by a reliable and competent partner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
