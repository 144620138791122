import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="inner-banner">
        <div className="w3l-breadcrumb py-5" style={{ paddingTop: "8rem" }}>
          <div className="container py-xl-5 py-md-4 mt-5">
            <h4 className="inner-text-title font-weight-bold mb-sm-2">
              Surface Freight
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-chevron-right mx-2" aria-hidden="true" />
                Surface Freight
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-6 py-5" id="about">
        <div className="text-6-mian py-md-5 py-4">
          <div className="container">
            <div className="row top-cont-grid align-items-center">
              <div className="col-lg-12 left-img pr-lg-4 order-lg-first order-last">
                <img
                  src="assets/images/surface.jpg"
                  alt=""
                  className="img-responsive img-fluid"
                />
              </div>
              <div className="col-lg-12 text-6-info mb-lg-0 mb-5 pl-xl-5 order-lg-last order-first">
                <h5 className="small-title-2">
                  {" "}
                  <br />
                </h5>
                <h3 className="title-style-2 mb-3">Surface Freight</h3>
                <p>
                  Leveraging cutting-edge vehicle tracking technologies, we
                  excel in arranging both Full Truck Load (FTL) and Less Than
                  Truck Load (LTL) shipments across India. Our advanced tracking
                  systems ensure real-time monitoring and provide precise
                  updates on the movement of your cargo. Offering a
                  comprehensive point-to-point delivery service, we cater to the
                  needs of our customers, ensuring their goods reach their
                  destination conveniently, no matter where they are located.
                  <br />
                  <br />
                  At every step of the transportation process, we prioritize
                  cost-effectiveness and reliability. Our experienced team
                  provides valuable guidance to our customers, helping them
                  select the most efficient and economical routes to swiftly
                  deliver their valued orders to their clients. With our
                  seamless logistics solutions, you can rest assured that your
                  cargo will be transported economically and expeditiously,
                  bolstering your business operations and enhancing customer
                  satisfaction.
                  <br />
                  <br />
                  As a forward-thinking logistics company, we continually
                  embrace technological advancements to improve our services and
                  offer our clients a seamless shipping experience. Our
                  commitment to excellence drives us to explore innovative
                  solutions and ensure that we remain at the forefront of the
                  logistics industry, empowering businesses to thrive in the
                  competitive market by efficiently managing their
                  transportation needs across India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
