import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      {/* banner section */}
      <section className="banner-19">
        <div className="banner-layer">
          <video autoPlay muted loop id="myVideo">
            <source src="assets/videos/video.mp4" type="video/mp4" />
          </video>
          <div className="main-content-top">
            <div className="container">
              <div className="main-content">
                {/* if logo is image enable this   
                        <Link class="logo" to="index.html">
                            <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
                        </Link> */}
                <h4>
                  Where Innovation Meets Reliability in Freight Forwarding.{" "}
                </h4>
                <p className="mt-3">
                  Embracing cutting-edge innovation while upholding unwavering
                  reliability, we offer top-tier freight forwarding services
                  that redefine industry standards.{" "}
                </p>
                <Link to="/About" className="btn btn-style mt-md-5 mt-4">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //banner section */}
      {/* banner bottom section */}

      <div className="service-section py-5">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center mb-5">
            <div className="col-md-6 section-heading">
              <h5 className="small-title-2">Our Services</h5>
              <h3 className="title-style-2">What We Do</h3>
            </div>
            <div className="col-md-6 section-heading mt-md-0 mt-2">
              <p>
                Unlock the full potential of your supply chain with our
                top-notch freight services, delivering efficiency, reliability,
                and peace of mind.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 item">
              <div className="card">
                <div className="card-header p-0 position-relative border-0">
                  <Link to="/Air">
                    <img
                      className="d-block img-responsive"
                      src="assets/images/air.jpg"
                      alt="card-image "
                      style={{ height: "200px" }}
                    />
                  </Link>
                </div>
                <div className="card-body service-details">
                  <span className="label-style">Service</span>
                  <Link to="/Air" className="service-heading">
                    Air Freight
                  </Link>
                  <p>
                  With our extensive expertise in the logistics industry, we
                  take pride in offering comprehensive shipment arrangements on
                  a global scale, utilizing both air and sea modes. Our
                  specialized guidance ensures that you receive the most
                  cost-effective and reliable routing, facilitating swift and
                  economical delivery of valued orders to your clients. We
                  meticulously monitor carrier status to ensure timely and
                  secure delivery, putting your mind at ease throughout the
                  shipping process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item">
              <div className="card">
                <div className="card-header p-0 position-relative border-0">
                  <Link to="/Sea">
                    <img
                      className="d-block img-responsive"
                      src="assets/images/sea.jpg"
                      alt="card-image "
                      style={{ height: "200px" }}
                    />
                  </Link>
                </div>
                <div className="card-body service-details">
                  <span className="label-style">Service</span>
                  <Link to="/Sea" className="service-heading">
                    Sea Freight
                  </Link>
                  <p>
                  {" "}
                  Our global reach allows us to efficiently arrange Full
                  Container Load (FCL) and Less than Container Load (LCL)
                  shipments for our esteemed customers across the world. With a
                  strong emphasis on fostering positive relationships with
                  leading liners, we can secure competitive prices and top-notch
                  services for our clients. Rest assured, your goods will be
                  delivered swiftly, accurately, and securely, ensuring utmost
                  satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item">
              <div className="card">
                <div className="card-header p-0 position-relative border-0">
                  <Link to="/Surface">
                    <img
                      className="d-block img-responsive"
                      src="assets/images/surface.jpg"
                      alt="card-image "
                      style={{ height: "200px" }}
                    />
                  </Link>
                </div>
                <div className="card-body service-details">
                  <span className="label-style">Service</span>
                  <Link to="/Surface" className="service-heading">
                    Surface Freight
                  </Link>
                  <p>
                  Leveraging cutting-edge vehicle tracking technologies, we
                  excel in arranging both Full Truck Load (FTL) and Less Than
                  Truck Load (LTL) shipments across India. Our advanced tracking
                  systems ensure real-time monitoring and provide precise
                  updates on the movement of your cargo. Offering a
                  comprehensive point-to-point delivery service, we cater to the
                  needs of our customers, ensuring their goods reach their
                  destination conveniently, no matter where they are located.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //services section */}
      {/* about section */}
      <div className="w3l-content-photo-5 py-5">
        <div className="container py-lg-5 py-sm-4">
          <div className="row align-items-center">
            <div className="col-lg-6 content-image order-lg-first order-last">
              <Link to="#image">
                <img
                  src="assets/images/about.jpg"
                  className="img-responsive"
                  alt="content-photo"
                />
              </Link>
            </div>
            <div className="col-lg-6 col-md-10 mx-auto content-right mb-lg-0 mb-5 pl-lg-5 order-lg-last order-first">
              <h5 className="small-title-2">About Our Company</h5>
              <h3 className="title-style-2">{companyname}</h3>
              <p className="my-3">
                {companyname}, is an freight forwarding company and custom
                broker dedicated to serving the shipping sector with a diverse
                range of high-quality services. Our commitment to excellence and
                customer satisfaction drives us to provide comprehensive
                solutions, from supplies and maintenance to operational support.
                <br />
                With a well-established network of strategic alliances
                worldwide, we ensure efficient and reliable freight services
                across air, sea, and road transportation. As a young and
                ambitious company, every member of {companyname} is dedicated to
                becoming an expert in the field, continuously striving to exceed
                customer expectations.
                
              </p>
             
              <Link
                  to="/About"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
              {/* dialog itself, mfp-hide class is required to make dialog hidden */}
              
            </div>
          </div>
        </div>
      </div>
      {/* //about section */}
      {/* team section */}
      <section className="w3l-team py-5">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center mb-5">
            <div className="col-md-6 section-heading">
              <h5 className="small-title-2">What We Provide </h5>
              <h3 className="title-style-2">Other Services</h3>
            </div>
            <div className="col-md-6 section-heading mt-md-0 mt-2">
             
            </div>
          </div>
          <div className="row text-center">
            <div className="col-lg-4 col-sm-6">
              <div className="team-block-single">
                <div className="team-grids">
                  <Link to="/Custom">
                    <img
                      src="assets/images/custom.webp"
                      style={{height:"200px"}}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="team-info">
                  <Link
                  to="/Custom"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
                  </div>
                </div>
                <div className="team-bottom-block p-4">
                  <h5 className="member mb-1">
                    <Link to="/Custom">Custom Clearance</Link>
                  </h5>
                  <small>Service</small>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="team-block-single">
                <div className="team-grids">
                  <Link to="/Courier">
                    <img
                      src="assets/images/courier.jpg"
                      style={{height:"200px"}}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="team-info">
                  <Link
                  to="/Courier"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
                  </div>
                </div>
                <div className="team-bottom-block p-4">
                  <h5 className="member mb-1">
                    <Link to="/Courier">Courier Freight</Link>
                  </h5>
                  <small>Service</small>
                </div>
              </div>
            </div>

             <div className="col-lg-4 col-sm-6">
              <div className="team-block-single">
                <div className="team-grids">
                  <Link to="/Warehousing">
                    <img
                      src="assets/images/warehousing.jpg"
                      style={{height:"200px"}}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="team-info">
                  <Link
                  to="/Warehousing"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
                  </div>
                </div>
                <div className="team-bottom-block p-4">
                  <h5 className="member mb-1">
                    <Link to="/Warehousing">Warehousing</Link>
                  </h5>
                  <small>Service</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* content section */}
      <section className="home-services py-5">
          <div className="container py-md-5 py-4">
            <div className="section-heading text-center mb-5">
              <h3 className="title-style-2">Why Choose Us</h3>
            </div>
            <div className="row justify-content-center">
              <div className="box-wrap col-lg-4 col-md-6">
                <div className="box-wrap-grid text-center">
                  <div className="icon mb-4">
                  <span class="fa fa-eye" aria-hidden="true"/>
                  </div>
                  <div className="info">
                    <h4> Our Vision</h4>
                  </div>
                  <p className="mt-3">We take immense pride in delivering the highest standard of freight forwarding customer service. With our expertise and dedication, we offer...</p>
                </div>
              </div>
              <div className="box-wrap col-lg-4 col-md-6">
                <div className="box-wrap-grid text-center">
                  <div className="icon mb-4">
                  <span class="fa fa-bullseye" aria-hidden="true"/>
                  </div>
                  <div className="info">
                    <h4> Our Mission</h4>
                  </div>
                  <p className="mt-3"> Our commitment lies in providing our clients with a comprehensive range of cost-effective forwarding solutions. We strive to offer...</p>
                </div>
              </div>
              <div className="box-wrap col-lg-4 col-md-6">
                <div className="box-wrap-grid text-center">
                  <div className="icon mb-4">
                  <span class="fa fa-handshake-o" aria-hidden="true"/>
                  </div>
                  <div className="info">
                    <h4> Our Experience</h4>
                  </div>
                  <p className="mt-3">Based on our extensive experience, logistics management plays a pivotal role in the logistics industry...</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      

      <Footer />
    </>
  );
}
