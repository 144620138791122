import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
   useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <>
      <header id="site-header" className="fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg stroke">
            <h1>
              <Link
                className="navbar-brand d-flex align-items-center"
                to="index.html"
              >
                <img
                  src="logo.png"
                  alt="Your logo"
                  title="Your logo"
                  style={{ height: "120px  " }}
                />
              </Link>
            </h1>
            {/* if logo is image enable this   
    <Link class="navbar-brand" to="#index.html">
        <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
    </Link> */}
            <button
              className="navbar-toggler  collapsed bg-gradient"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars" />
              <span className="navbar-toggler-icon fa icon-close fa-times" />
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ml-lg-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/Home">
                    Home <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    Who We Are
                  </Link>
                </li>
                <div class="dropdown">
                  <Link
                    class="nav-link dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    What We Do
                    <span class="caret"></span>
                  </Link>
                  <ul
                    class="dropdown-menu"
                    style={{padding: "10px 10px"}}
                  >
                    <li className="nav-item">
                      <Link to="/Air"> Air Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Sea"> Sea Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Surface"> Surface Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Custom"> Custom Clearance</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Courier"> Courier Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Warehousing"> Warehousing & Packaging</Link>
                    </li>
                   
                  </ul>
                </div>
                <li className="nav-item">
                  <Link className="nav-link" to="/Contact">
                    Contact Us
                  </Link>
                </li>
                {/* search button */}
                <div className="search-right ml-lg-3">
                  <Link
                  to="/Getquote"
                    name="submitquote"
                    defaultValue="SEND"
                    className="btn btn-style "
                  >
                    Request A Quote
                  </Link>
                </div>
                {/* //search button */}
              </ul>
            </div>
            {/* toggle switch for light and dark theme */}
           
          </nav>
        </div>
      </header>

      <Outlet />
    </>
  );
};

export default Header;
