import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="inner-banner">
      <div className="w3l-breadcrumb py-5" style={{paddingTop:"8rem"}}>
            <div className="container py-xl-5 py-md-4 mt-5">
              <h4 className="inner-text-title font-weight-bold mb-sm-2">Who We Are</h4>
              <ul className="breadcrumbs-custom-path">
                <li><Link to="/Home">Home</Link></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true" />Who We Are</li>
              </ul>
            </div>
          </div>
        </section>
      <section className="w3l-text-6 py-5" id="about">
          <div className="text-6-mian py-md-5 py-4">
            <div className="container">
              <div className="row top-cont-grid align-items-center">
                <div className="col-lg-6 left-img pr-lg-4 order-lg-first order-last">
                  <img src="assets/images/about.jpg" alt="" className="img-responsive img-fluid" />
                  <div className="row mt-3">
                    <div className="col-6 img-pad pr-2">
                      <img src="assets/images/about1.jpg" alt="" className="img-responsive img-fluid" />
                    </div>
                    <div className="col-6 img-pad-2 pl-2">
                      <img src="assets/images/about2.jpg" alt="" className="img-responsive img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 text-6-info mb-lg-0 mb-5 pl-xl-5 order-lg-last order-first">
                  <h5 className="small-title-2">About Our Company</h5>
                  <h3 className="title-style-2 mb-3">
                  {companyname}</h3>
                  <p> {companyname}, is an freight forwarding company and custom
                broker dedicated to serving the shipping sector with a diverse
                range of high-quality services. Our commitment to excellence and
                customer satisfaction drives us to provide comprehensive
                solutions, from supplies and maintenance to operational support.
                <br />
                <br />
                With a well-established network of strategic alliances
                worldwide, we ensure efficient and reliable freight services
                across air, sea, and road transportation. As a young and
                ambitious company, every member of {companyname} is dedicated to
                becoming an expert in the field, continuously striving to exceed
                customer expectations.
                <br />
                <br />
                Our specialized services encompass custom clearance,
                warehousing, consolidation, transshipment, commodity inspection,
                insurance, and expert consulting. Whether you need assistance
                with domestic or international shipments, trust {companyname} to
                handle your freight needs with utmost care and professionalism.
                Contact us now with your valuable inquiries, and experience
                seamless shipping solutions tailored to your specific
                requirements.
                <br /></p>
                 
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* //about section */}
        {/* why section */}
        <section className="home-services py-5">
          <div className="container py-md-5 py-4">
            <div className="section-heading text-center mb-5">
              <h3 className="title-style-2">Why Choose Us</h3>
            </div>
            <div className="row justify-content-center">
              <div className="box-wrap col-lg-4 col-md-6">
                <div className="box-wrap-grid text-center">
                  <div className="icon mb-4">
                  <span class="fa fa-eye" aria-hidden="true"/>
                  </div>
                  <div className="info">
                    <h4> Our Vision</h4>
                  </div>
                  <p className="mt-3">We take immense pride in delivering the highest standard of freight forwarding customer service. With our expertise and dedication, we offer...</p>
                </div>
              </div>
              <div className="box-wrap col-lg-4 col-md-6">
                <div className="box-wrap-grid text-center">
                  <div className="icon mb-4">
                  <span class="fa fa-bullseye" aria-hidden="true"/>
                  </div>
                  <div className="info">
                    <h4> Our Mission</h4>
                  </div>
                  <p className="mt-3"> Our commitment lies in providing our clients with a comprehensive range of cost-effective forwarding solutions. We strive to offer...</p>
                </div>
              </div>
              <div className="box-wrap col-lg-4 col-md-6">
                <div className="box-wrap-grid text-center">
                  <div className="icon mb-4">
                  <span class="fa fa-handshake-o" aria-hidden="true"/>
                  </div>
                  <div className="info">
                    <h4> Our Experience</h4>
                  </div>
                  <p className="mt-3">Based on our extensive experience, logistics management plays a pivotal role in the logistics industry...</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </>
  );
}
