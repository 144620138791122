import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section className="inner-banner">
      <div className="w3l-breadcrumb py-5" style={{paddingTop:"8rem"}}>
            <div className="container py-xl-5 py-md-4 mt-5">
              <h4 className="inner-text-title font-weight-bold mb-sm-2">Contact Us</h4>
              <ul className="breadcrumbs-custom-path">
                <li><Link to="/Home">Home</Link></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true" />Contact Us</li>
              </ul>
            </div>
          </div>
        </section>
        {/* //inner banner */}
        {/* contact */}
        <section className="w3l-contact-info-main py-5" id="contact">
          <div className="container py-md-5 py-4">
            <div className="row align-items-center mb-5">
              <div className="col-md-6 section-heading">
                <h5 className="small-title-2">Write Message</h5>
                <h3 className="title-style-2">Get in Touch</h3>
              </div>
              <div className="col-md-6 section-heading mt-md-0 mt-2">
                <p>
                Take the first step towards seamless logistics by filling out our contact form – we're here to make shipping easier for you.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="contact-address p-4">
                  <div className="contact-icon d-flex align-items-center">
                    <i className="fas fa-map-marker-alt" aria-hidden="true" />
                    <div className="ml-3">
                      <h5 className="contact-text">Visit Us:</h5>
                      <p>{companyaddress}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                <div className="contact-address p-4">
                  <div className="contact-icon d-flex align-items-center">
                    <i className="fas fa-phone-alt" aria-hidden="true" />
                    <div className="ml-3">
                      <h5 className="contact-text">Call Us:</h5>
                      <a href="tel:{companynumber"> {companynumber}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                <div className="contact-address p-4">
                  <div className="contact-icon d-flex align-items-center">
                    <i className="fas fa-envelope-open-text" aria-hidden="true" />
                    <div className="ml-3">
                      <h5 className="contact-text">Mail Us:</h5>
                      <a href="{companyemail}"> {companyemail}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-w3pvt-form mt-5 pt-lg-4">
            <form
            className="w3layouts-contact-fm"
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input className="form-control"
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input className="form-control"
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input className="form-control"
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input className="form-control"
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        className="form-control"
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="submit-w3l-button text-lg-right">
                    <button
                      name="submit"
                      className="btn btn-style"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h1>{result}</h1>
            </div>
          </div>
        </section>
        <div className="map-contact">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60275.69726106052!2d72.9344066!3d19.2287584!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b92abc14627f%3A0x67321c4ebf19795e!2sLake%20City%20Mall!5e0!3m2!1sen!2sin!4v1690354023087!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> </div>

      <Footer />
    </>
  );
}
