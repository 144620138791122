import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="inner-banner">
      <div className="w3l-breadcrumb py-5" style={{paddingTop:"8rem"}}>
            <div className="container py-xl-5 py-md-4 mt-5">
              <h4 className="inner-text-title font-weight-bold mb-sm-2">Courier Freight</h4>
              <ul className="breadcrumbs-custom-path">
                <li><Link to="/Home">Home</Link></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true" />Courier Freight</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="w3l-text-6 py-5" id="about">
          <div className="text-6-mian py-md-5 py-4">
            <div className="container">
              <div className="row top-cont-grid align-items-center">
                <div className="col-lg-12 left-img pr-lg-4 order-lg-first order-last">
                  <img src="assets/images/courier.jpg" alt="" className="img-responsive img-fluid" />
                  
                </div>
                <div className="col-lg-12 text-6-info mb-lg-0 mb-5 pl-xl-5 order-lg-last order-first">
                  <h5 className="small-title-2"> <br/></h5> 
                  <h3 className="title-style-2 mb-3">
                 Courier Freight</h3>
                  <p>At our company, we offer efficient and reliable courier services to transport small cargo from door to door worldwide. Through our unique agreements with leading courier companies, our customers benefit from seamless and speedy courier delivery options. Our dedicated air freight team takes charge of managing these courier services, ensuring a smooth and hassle-free process from the supplier's door to the customer's door, anywhere in the world.<br/><br/>

With us, clients enjoy a comprehensive range of services and competitive prices, all conveniently accessible under one roof. We prioritize customer satisfaction and strive to provide top-notch courier solutions tailored to meet the specific needs of each client. Whether it's urgent documents, small packages, or time-sensitive parcels, our courier services ensure timely and secure delivery, offering peace of mind to our valued customers. Rely on our expertise and global network to make your courier shipments swift, cost-effective, and hassle-free.<br/><br/>

                  </p>
                 
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </>
  );
}
