import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
       <footer className="w3l-footer-22 py-5">
          <div className="container py-md-5 py-4">
            <div className="row sub-columns">
              <div className="col-lg-5 col-sm-6 sub-one-left">
                <h6>About </h6>
                <p>{companyname}, is an freight forwarding company and custom
                broker dedicated to serving the shipping sector with a diverse
                range of high-quality services. Our commitment to excellence and
                customer satisfaction drives us to provide comprehensive
                solutions, from supplies and maintenance to operational support.</p>
                <div className="columns-2">
                <Link
                  to="/About"
                    className="btn btn-style "
                  >
                    Learn More
                  </Link>
                  </div></div>
              <div className="col-lg-4 col-sm-6 sub-two-right pl-lg-5 mt-sm-0 mt-5">
                <h6>Our Services</h6>
                <ul>
                <li className="nav-item">
                      <Link to="/Air"> Air Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Sea"> Sea Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Surface"> Surface Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Custom"> Custom Clearance</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Courier"> Courier Freight</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Warehousing"> Warehousing & Packaging</Link>
                    </li>
                </ul>
              </div>
              <div className="col-lg-3 col-sm-6 sub-two-right mt-lg-0 mt-sm-5 mt-4">
                <h6>Quick links</h6>
                <ul>
                <li className="nav-item">
                      <Link to="/Home"> Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/About"> About Us</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Services"> Services</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Contact"> Contact</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Getquote"> Getquote</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Privacy"> Privacy Policy</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Terms"> Terms </Link>
                    </li>
                </ul>
              </div>
             
            </div>
            <div className="sub-contact mt-5 pt-lg-4">
              <div className="row">
                <div className="col-md-4 footer-contact d-flex align-items-center">
                  <span className="fas fa-envelope-open" aria-hidden="true" />
                  <div>
                    <Link to="mailto:{companyemail}">{companyemail}</Link>
                    <p>Drop Us a Line</p>
                  </div>
                </div>
                <div className="col-md-4 footer-contact d-flex align-items-center active">
                  <span className="fas fa-phone-alt" aria-hidden="true" />
                  <div>
                    <Link to="tel:{companynumber">{companynumber}</Link>
                    <p>Call Us Now</p>
                  </div>
                </div>
                <div className="col-md-4 footer-contact d-flex align-items-center">
                  <span className="fas fa-map-marker-alt" aria-hidden="true" />
                  <div>
                    <h5> {companyaddress}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* copyright */}
          <div className="copyright-footer text-center">
            <div className="container">
              <div className="columns">
                <p>@2023 {companyname}. All rights reserved. 
                </p>
              </div>
            </div>
          </div>
          {/* //copyright */}
        </footer>
        {/* //footer */}
        {/* Js scripts */}
        {/* move top */}
        <button onclick="topFunction()" id="movetop" title="Go to top">
          <span className="fas fa-level-up-alt" aria-hidden="true" />
        </button>
    </>
  );
};

export default Footer;
