import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="inner-banner">
      <div className="w3l-breadcrumb py-5" style={{paddingTop:"8rem"}}>
            <div className="container py-xl-5 py-md-4 mt-5">
              <h4 className="inner-text-title font-weight-bold mb-sm-2">Custom Clearance</h4>
              <ul className="breadcrumbs-custom-path">
                <li><Link to="/Home">Home</Link></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true" />Custom Clearance</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="w3l-text-6 py-5" id="about">
          <div className="text-6-mian py-md-5 py-4">
            <div className="container">
              <div className="row top-cont-grid align-items-center">
                <div className="col-lg-12 left-img pr-lg-4 order-lg-first order-last">
                  <img src="assets/images/custom.webp" alt="" className="img-responsive img-fluid" />
                  
                </div>
                <div className="col-lg-12 text-6-info mb-lg-0 mb-5 pl-xl-5 order-lg-last order-first">
                  <h5 className="small-title-2"> <br/></h5>
                  <h3 className="title-style-2 mb-3"> 
                Custom Clearance</h3>
                  <p>
                  With our extensive experience in customs clearance, we expertly handle both import and export processes across all ports and Inland Container Depots (ICDs) in India. Our dedicated team ensures timely clearance, allowing us to deliver your consignments within the designated free storage period, effectively avoiding any additional storage and demurrage charges for our valued customers.<br/><br/>

Our specialization lies in custom clearance for various categories, and you can confidently rely on us for seamless and efficient clearance services. Whether it's industrial machinery, consumer goods, electronics, or any other type of cargo, we have the expertise and know-how to facilitate hassle-free customs procedures for your shipments.<br/><br/>

By entrusting your customs clearance to us, you can focus on your core business while we handle all the complexities and regulatory requirements on your behalf. Our commitment to customer satisfaction drives us to provide transparent, reliable, and cost-effective customs clearance solutions, ensuring the smooth and swift movement of your goods across borders.
                  </p>
                 
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </>
  );
}
