import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="inner-banner">
        <div className="w3l-breadcrumb py-5" style={{ paddingTop: "8rem" }}>
          <div className="container py-xl-5 py-md-4 mt-5">
            <h4 className="inner-text-title font-weight-bold mb-sm-2">
              Air Freight
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-chevron-right mx-2" aria-hidden="true" />
                Air Freight
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-6 py-5" id="about">
        <div className="text-6-mian py-md-5 py-4">
          <div className="container">
            <div className="row top-cont-grid align-items-center">
              <div className="col-lg-12 left-img pr-lg-4 order-lg-first order-last">
                <img
                  src="assets/images/air.jpg"
                  alt=""
                  className="img-responsive img-fluid"
                />
              </div>
              <div className="col-lg-12 text-6-info mb-lg-0 mb-5 pl-xl-5 order-lg-last order-first">
                <h5 className="small-title-2">
                  {" "}
                  <br />
                </h5>
                <h3 className="title-style-2 mb-3">Air Freight</h3>
                <p>
                  With our extensive expertise in the logistics industry, we
                  take pride in offering comprehensive shipment arrangements on
                  a global scale, utilizing both air and sea modes. Our
                  specialized guidance ensures that you receive the most
                  cost-effective and reliable routing, facilitating swift and
                  economical delivery of valued orders to your clients. We
                  meticulously monitor carrier status to ensure timely and
                  secure delivery, putting your mind at ease throughout the
                  shipping process.
                  <br />
                  <br />
                  No matter the size, type, or value of your cargo, we provide
                  personalized assistance in evaluating the most efficient,
                  secure, and economically viable shipping options. Our
                  commitment to excellence extends to large-volume and repeat
                  shippers, as we offer discounted spot tariffs due to the high
                  volumes we handle. We understand the importance of meeting
                  your shipping requirements promptly and efficiently, and our
                  dedicated team is always ready to tailor solutions that align
                  with your unique business needs.
                  <br />
                  <br />
                  At our core, we prioritize customer satisfaction, and our
                  logistics management expertise empowers us to optimize the
                  transportation of your goods worldwide. Let us be your trusted
                  logistics partner, ensuring seamless and cost-effective
                  shipping solutions that propel your business to new heights.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
