import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="inner-banner">
        <div className="w3l-breadcrumb py-5" style={{ paddingTop: "8rem" }}>
          <div className="container py-xl-5 py-md-4 mt-5">
            <h4 className="inner-text-title font-weight-bold mb-sm-2">
              Sea Freight
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-chevron-right mx-2" aria-hidden="true" />
                Sea Freight
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-6 py-5" id="about">
        <div className="text-6-mian py-md-5 py-4">
          <div className="container">
            <div className="row top-cont-grid align-items-center">
              <div className="col-lg-12 left-img pr-lg-4 order-lg-first order-last">
                <img
                  src="assets/images/sea.jpg"
                  alt=""
                  className="img-responsive img-fluid"
                />
              </div>
              <div className="col-lg-12 text-6-info mb-lg-0 mb-5 pl-xl-5 order-lg-last order-first">
                <h5 className="small-title-2">
                  {" "}
                  <br />
                </h5>
                <h3 className="title-style-2 mb-3">Sea Freight</h3>
                <p>
                  {" "}
                  Our global reach allows us to efficiently arrange Full
                  Container Load (FCL) and Less than Container Load (LCL)
                  shipments for our esteemed customers across the world. With a
                  strong emphasis on fostering positive relationships with
                  leading liners, we can secure competitive prices and top-notch
                  services for our clients. Rest assured, your goods will be
                  delivered swiftly, accurately, and securely, ensuring utmost
                  satisfaction.
                  <br />
                  <br />
                  The growing number of customers availing our sea freight
                  services stands as a testament to our commitment to
                  excellence. We take pride in the trust placed in us by our
                  expanding clientele, and we continuously strive to exceed
                  their expectations with seamless and reliable sea freight
                  solutions. Our dedicated team of experts diligently manages
                  every aspect of the shipping process, ensuring a hassle-free
                  experience for our valued customers.
                  <br />
                  <br />
                  As a customer-centric freight forwarding company, we
                  understand the importance of timely and cost-effective
                  shipments in driving the success of your business. With our
                  comprehensive sea freight services, you can confidently expand
                  your global reach and deliver your products to international
                  markets with ease. Count on us for an exceptional shipping
                  experience that strengthens your business's position in the
                  global trade arena.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
